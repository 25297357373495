import { useEffect } from 'react';
import { Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

export const Disclaimer = () => {

    return (
        <Flex w="full" gap={10} direction="column" px={5} py={5}>
            <Heading>
                Disclaimer: Risks and Responsibilities of Staking on This
                Platform
            </Heading>
            <Text size="md" mb={-4}>
                Engaging in staking activities on this platform carries
                substantial risks, and it may not be suitable for every
                individual. By choosing to participate in staking, you
                acknowledge that you have thoroughly reviewed and understood the
                inherent risks involved. These risks include, but are not
                limited to, market volatility, potential loss of staked assets,
                security breaches, liquidity challenges, and the impact of
                changes in network protocols or underlying blockchain
                technology.
            </Text>

            <Heading size="md" mb={-7}>Market Volatility and Asset Fluctuation</Heading>
            <Text size="md" mb={-4}>
                The value of assets staked on this platform can fluctuate
                significantly due to various market forces, and there is no
                guarantee of achieving any specific returns or outcomes. Prices
                in cryptocurrency markets are inherently volatile, and as a
                result, the value of your staked assets may rise or fall sharply
                within short timeframes. It is critical to acknowledge that you
                may experience losses, including the total loss of your staked
                assets, without any compensation from the platform or its
                partners.
            </Text>
            <Heading size="md" mb={-7}>
                Network Protocol and Technological Changes
            </Heading>
            <Text size="md" mb={-4}>
                Changes in network protocols, blockchain technologies, or any
                associated infrastructure can have profound effects on your
                staking experience. These changes may result from upgrades, hard
                forks, technical adjustments, or unforeseen issues with the
                blockchain ecosystem. The platform cannot and does not guarantee
                the continued operation of the underlying blockchain network on
                which staking is dependent, nor can it predict the outcomes of
                any changes that may affect the functionality, liquidity, or
                stability of your staked assets.
            </Text>
            <Heading size="md" mb={-7}>Early Unstake Fees and Terms</Heading>
            <Text size="md" mb={-4}>
                If you choose to unstake your assets before the end of the
                designated staking period, an early unstake fee may be applied.
                This fee is fixed, non-negotiable, and will be deducted from
                your staked assets. The terms and conditions associated with
                this early unstaking fee, as well as the time frames and
                percentages involved, are clearly outlined in the platform’s
                guidelines. It is your responsibility to familiarize yourself
                with these terms prior to staking to avoid any misunderstandings
                or unexpected penalties.
            </Text>
            <Heading size="md" mb={-7}>Minimum Staking Requirements</Heading>
            <Text size="md" mb={-4}>
                In addition, there may be a minimum staking requirement that
                must be met in order to participate in staking activities on
                this platform. This requirement is clearly outlined in the
                staking terms and conditions and is enforced to maintain the
                integrity of the staking system. Failure to meet the minimum
                staking requirement may result in your inability to stake or
                participate in certain rewards programs. You must ensure that
                your staked assets meet these requirements prior to engaging in
                staking activities.
            </Text>
            <Heading size="md" mb={-7}>Not Financial Advice</Heading>
            <Text size="md" mb={-4}>
                It is important to note that this platform is not designed to
                provide investment, tax, legal, or financial advice. All
                information available on the platform is provided solely for
                informational purposes and should not be interpreted as a
                recommendation or endorsement of any particular staking activity
                or investment strategy. You should consult a qualified financial
                advisor, tax expert, or legal professional to assess your
                specific situation and determine whether staking on this
                platform aligns with your financial objectives, risk tolerance,
                and legal obligations.
            </Text>
            <Heading size="md" mb={-7}>Conducting Due Diligence</Heading>
            <Text size="md" mb={-4}>
                Before making any decision related to staking, you are strongly
                encouraged to conduct comprehensive research and perform due
                diligence. This includes assessing the current market
                conditions, evaluating the potential risks, understanding the
                technology behind staking, and reviewing the platform’s terms
                and conditions in detail. The platform provides access to
                educational resources and guides, but the ultimate
                responsibility for making informed decisions lies with you as
                the user.
            </Text>
            <Heading size="md" mb={-7}>
                Platform Liability and Disclaimer of Responsibility
            </Heading>
            <Text size="md" mb={-4}>
                The platform disclaims any responsibility for losses that may
                arise from staking activities. These losses may stem from market
                fluctuations, technical failures, hacking or cyber-attacks,
                unforeseen disruptions in the blockchain ecosystem, liquidity
                issues, or any other unforeseen events. The platform does not
                guarantee the security or stability of the network and will not
                be held liable for any financial losses or adverse consequences
                that may result from staking activities.
                <br />
                By engaging in staking, you accept that you are participating at
                your own risk and that the platform cannot provide guarantees or
                assurances regarding the safety, profitability, or success of
                your staking endeavors. Additionally, the platform reserves the
                right to make changes to its terms, conditions, or fee
                structures at any time, and it is your responsibility to stay
                informed of any updates.
            </Text>
            <Heading size="md" mb={-7}>Evaluating Your Financial Situation</Heading>
            <Text size="md" mb={-4}>
                Staking is inherently a high-risk activity, and it may not be
                suitable for individuals with low risk tolerance or those unable
                to withstand potential financial losses. You are advised to
                carefully evaluate your financial situation, investment
                objectives, and liquidity needs before participating in staking
                activities. It is recommended that you only stake amounts that
                you can afford to lose without jeopardizing your financial
                well-being or long-term goals.
            </Text>
            <Heading size="md" mb={-7}>Seek Professional Advice</Heading>
            <Text size="md" mb={-4}>
                We strongly encourage users to seek guidance from qualified
                financial advisors or legal professionals if they have any
                uncertainties or concerns regarding the risks involved in
                staking. A professional assessment of your financial position,
                investment strategy, and legal obligations can help you make
                more informed decisions and avoid potential pitfalls.
                Participating in staking without proper consultation may lead to
                unforeseen consequences, including significant financial losses.
            </Text>
            <Heading size="md" mb={-7}>Voluntary Participation</Heading>
            <Text size="md" mb={-4}>
                Participation in staking is entirely voluntary, and you should
                not feel obligated to stake assets beyond your comfort level.
                You are under no obligation to participate, and the decision to
                stake is solely at your discretion. The platform provides
                transparent information to help you make an informed choice, but
                it is ultimately your responsibility to decide whether staking
                aligns with your personal financial situation and risk
                tolerance.
            </Text>
            <Heading size="md" mb={-7}>Acceptance of Terms</Heading>
            <Text size="md" mb={-4}>
                By utilizing this platform and engaging in staking activities,
                you agree to adhere to the terms and conditions outlined in this
                disclaimer and the platform’s staking guidelines. You also
                accept full responsibility for any actions you take while using
                the platform, including any financial outcomes resulting from
                your participation in staking.
                <br />
                <br />
                This extended disclaimer aims to provide a more thorough
                understanding of the risks involved and underscores the
                importance of seeking professional advice and conducting
                personal research before engaging in staking activities. The
                platform’s role is to facilitate staking opportunities, but it
                cannot predict or control the outcomes of those activities.
            </Text>
        </Flex>
    );
};
