import { useEffect } from "react";
import { Flex, Box, Heading, Text, Image } from "@chakra-ui/react";

export const AboutUs = () => {
    return (
        <Flex w="full" direction="column" gap={20} px={5} py={10}>
            {/* Section 1: Image Left, Content Right */}
            <Flex
                w="full"
                direction={{ base: "column", md: "row" }}
                align="center"
                gap={10}
            >
                <Box w={{ base: "100%", md: "100%" }}>
                    <Heading as="h2" mb={4}>
                        iFaast: Accelerating the Web3 Revolutions
                    </Heading>
                    <Text mb={4}>
                        At iFasst, we are at the forefront of the decentralized
                        finance (DeFi) revolution, committed to delivering a
                        transformative experience in the world of staking. Our
                        platform, which operates on the Polygon network, offers
                        a decentralized, user-friendly solution for individuals
                        looking to maximize the potential of their digital
                        assets. With a focus on security, transparency, and
                        simplicity, iFasst enables users to stake their assets
                        with ease and earn an impressive 60% annual percentage
                        yield (APY), all without the need for traditional
                        financial barriers such as KYC (Know Your Customer)
                        requirements.
                    </Text>
                    <Text mb={-5}>
                        We designed iFasst with a clear mission: to democratize
                        access to high-yield staking opportunities within the
                        DeFi ecosystem. Whether you're a crypto veteran or
                        someone just beginning their journey in the world of
                        digital assets, our platform allows you to effortlessly
                        connect through widely used wallets like MetaMask and
                        Trust Wallet. From there, you can seamlessly stake your
                        funds and begin earning significant rewards while
                        enjoying full control over your assets at every step of
                        the process.
                    </Text>
                </Box>
            </Flex>

            {/* Section 2: Content Left, Image Right */}
            <Flex
                w="full"
                direction={{ base: "column", md: "row-reverse" }}
                align="center"
                gap={10}
            >
                <Box mb={-15} w={{ base: "100%", md: "50%" }}>
                    <Image
                        src="https://img.freepik.com/free-photo/blockchain-technology-cartoon-illustration_23-2151572164.jpg?t=st=1728244870~exp=1728248470~hmac=037dd33353f937620772e3c82f9f88e3f7bc5b3ddf7a87b0e008991f833b6264&w=996"
                        alt="iFasst Image 2"
                        w="full"
                        h="auto"
                    />
                </Box>
                <Box w={{ base: "100%", md: "50%" }}>
                    <Heading as="h2" mb={4}>
                        Innovating for a Decentralized Future
                    </Heading>
                    <Text mb={4}>
                        Security is paramount at iFasst, and we take pride in
                        leveraging the secure architecture of the Polygon
                        network to provide a staking environment that is robust,
                        scalable, and resistant to vulnerabilities. By utilizing
                        decentralized smart contracts, iFasst ensures that your
                        staking experience is not only secure but also fully
                        transparent. Every transaction, reward distribution, and
                        staking event is immutably recorded on the blockchain,
                        ensuring an environment of trust, integrity, and
                        reliability.
                    </Text>
                    <Text mb={-15}>
                        However, iFasst is not just about staking. It is an
                        integral part of the larger Fasst ecosystem, which is
                        designed to create a multifaceted decentralized economy.
                    </Text>
                </Box>
            </Flex>

            {/* Section 3: Image Left, Content Right */}
            <Flex
                w="full"
                direction={{ base: "column", md: "row" }}
                align="center"
                gap={10}
            >
                <Box w={{ base: "100%", md: "100%" }}>
                    <Text mb={4}>
                        This ecosystem includes platforms and solutions across
                        various sectors such as freelancing, trading, payments,
                        rewards, entertainment, and gaming. These interconnected
                        services form the foundation of a digital future where
                        users can engage in trustless, peer-to-peer interactions
                        across a range of industries. As the Fasst ecosystem
                        grows, it will continue to provide opportunities for
                        users to participate in new and exciting decentralized
                        applications that push the boundaries of innovation.
                        <br />
                        <br />
                        The Fasst ecosystem is rooted in the principles of
                        decentralization and empowerment. We believe in creating
                        an inclusive financial future where users can access
                        opportunities that were previously reserved for
                        institutional players. With iFasst, we are breaking down
                        the barriers to entry, allowing anyone with digital
                        assets to participate in a high-yield staking system
                        that is both easy to use and highly rewarding. Our
                        vision is to provide users with financial freedom
                        through decentralized technology, offering solutions
                        that put control back in the hands of individuals rather
                        than centralized institutions.
                    </Text>
                    <Text>
                        At iFasst, we are continuously evolving and innovating.
                        Our team of experts is dedicated to enhancing the
                        platform, adding new features, and ensuring that our
                        staking services remain at the cutting edge of
                        decentralized finance. As the landscape of DeFi
                        continues to shift and expand, iFasst will adapt and
                        grow, introducing new staking options, partnerships, and
                        integrations that further empower our users.
                    </Text>
                    <br />
                    <Text>
                        In addition to staking, we are also exploring the
                        integration of additional financial products and
                        services that will allow users to engage with the
                        broader DeFi ecosystem. From decentralized exchanges
                        (DEXs) to lending and borrowing protocols, our goal is
                        to create a comprehensive platform that offers a full
                        suite of financial tools, all underpinned by the
                        security and transparency of blockchain technology.
                    </Text>
                    <br />
                    <Text>
                        Our vision for the future is bold. We believe in a world
                        where finance is decentralized, where individuals can
                        access opportunities on a global scale without
                        intermediaries or restrictions. iFasst is a critical
                        component of that vision, providing users with a
                        platform that combines high rewards with ease of use,
                        security, and transparency. We are excited about the
                        journey ahead and invite users from all over the world
                        to join us as we shape the future of decentralized
                        finance.
                    </Text>
                    <br />
                    <Text>
                        Whether you are looking to earn passive income through
                        staking or seeking to engage with the broader Fasst
                        ecosystem, iFasst is your gateway to a decentralized
                        financial future. Together, we are building a platform
                        that is designed to empower individuals, unlock new
                        opportunities, and push the boundaries of what is
                        possible in the world of decentralized finance. The
                        future is decentralized, and with iFasst, you can be a
                        part of it.
                    </Text>
                </Box>
            </Flex>
        </Flex>
    );
};
